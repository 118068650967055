.table-container {
    max-width: 100%;
}

.kiz-table {
    font-size: 12px;
    text-align: center;
    color: #4b4b4b;
    width: 100%;
    overflow-x: auto;
    table-layout: fixed;
}

.kiz-table td {
    height: 40px;
    border: 2px solid #e6ecfd;
    padding: 8px;
    width: 100%;
    word-wrap: break-word;
}

.kiz-table th {
    background: rgba(76, 120, 238, 0.14);
    height: 40px;
    padding: 0 24px;
}

.kiz-table .header {
    font-weight: 600;
    background: rgba(76, 120, 238, 0.14);
    padding: 0 24px;
}

.kiz-actions-wrapper {
    padding: 15px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: #fff;
    color: #82868b;

    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.kiz-actions-wrapper-left {
    display: flex;
    align-items: center;
    gap: 20px;
}

.customs-clearance-ciles-modal-wrap {
    display: flex;
    flex-direction: column;
    gap: 35px;
    margin-bottom: 20px;
}

.customs-clearance-ciles-modal-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.customs-clearance-ciles-modal-item-name {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #4B4B4B;
    cursor: pointer;
}

.customs-clearance-ciles-modal-item-delete {
    display: flex;
    align-items: center;
    gap: 5px;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #EA5455;

    cursor: pointer;
}


.send-to-tsd-modal-wrap {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.send-to-tsd-modal-wrap-item {
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    padding: 20px 13px;

    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #4B4B4B;
}

.custom-row-style {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
}

.tsd-wrap {
    display: flex;
    flex-direction: column;
    gap: 20px;

}

.tsd-item {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
}

.tsd-item-info {
    display: flex;
    flex-direction: row;
    gap: 10px;

    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #4B4B4B;
}

.tsd-item-dots {

}

.add-tsd{
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    gap: 9px;

    color: #4C78EE;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;

    cursor: pointer;
}
.add-tsd-file-name{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
}

@media (max-width: 768px) {
    .kiz-table td {
        font-size: 10px;
        padding: 6px;
    }

    .kiz-table th {
        font-size: 12px;
        padding: 6px;
    }
}

@media (max-width: 480px) {
    .kiz-table td {
        font-size: 8px;
        padding: 4px;
    }

    .kiz-table th {
        font-size: 10px;
        padding: 4px;
    }
}