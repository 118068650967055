/* FONTS */
@import "./styles/fonts/main.css";

/* OVERRIDE ANTD STYLES */
@import "./styles/override.css";

/* STYLES */
@import "./styles/login.css";
@import "./styles/header.css";
@import "./styles/sidenav.css";
@import "./styles/table.css";
@import "./styles/common.css";
@import "./styles/account.css";
@import "./styles/modal.css";
@import "./styles/financial-results.css";
@import "./styles/kiz.css";
@import "./styles/analytics.css";
@import "./styles/advertising-mirror.css";
@import "./styles/storage.css";
@import "./styles/products.css";
@import "./styles/mirror.css";
@import "./styles/auth.css";
@import "./styles/documentPage.css";
@import "./styles/innovationProposals.css";
@import "styles/shipments.scss";
@import "styles/ads_stats.scss";

body {
    height: 100%;
    margin: 0;
    min-width: 390px;
    font-family: "Montserrat", sans-serif !important;
}

#is-service-frame {
    display: none;
}

.ant-table-content::-webkit-scrollbar {
    height: 5px
}

.ant-table-content::-webkit-scrollbar-thumb {
    border-radius: 5px;
}







