.innovation-proposals-wrap {
    width: 100%;
    max-height: 85vh;
    min-height: 85vh;
    height: 100%;

    border-radius: 8px;
    background: #D9D9D9;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);

    padding: 30px;

    display: flex;
    flex-direction: column;
}

.innovation-proposals-top {
    flex: 3;
    max-height: 75%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.innovation-proposals-bottom {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.innovation-proposals-bottom-button-wrap {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: flex-end;
}

.innovation-proposals-bottom-button-button {
    max-width: 281px;
    width: 100%;
    background-color: #5C4E73;
}

.innovation-proposals-bottom-title {
    color: #171717;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.message-wrap {
    display: flex;
    justify-content: space-between;
    min-height: 100px;
    padding: 0 10px;
}

.message-left {
    position: relative;
}

.message-left::before {
    content: '';
    position: absolute;
    top: 10px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 1px;
    height: 100%;
    background-color: #BABFC7;
    z-index: 1;
}
.message-left .anticon {
    position: relative;
    z-index: 2;
    font-size: 20px;
}

.message-center {
    flex: 1;
    margin-left: 10px;
    padding-bottom: 50px;
}
.last-message::before {
    display: none;
}

.message-center-text {
    color: #171717;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 10px;
    word-wrap: break-word;
    word-break: break-word;
}

.message-right {
    margin-left: 10px;
    color: #5C4E73;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
}

.message-center-user{
    display: flex;
    gap: 14px
}

.message-center-user-data{
    display: flex;
    flex-direction: column;
}
.message-center-user-data-name{
    color: #171717;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
}
.message-center-user-data-name-position{
    color: #171717;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
}

.message-empty{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.message-empty-text{
    color: #171717;
    text-align: center;
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

@media (max-width: 750px) {
    .innovation-proposals-bottom-button-button {
        max-width: 100% !important;
    }
}