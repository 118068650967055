.container {
    background: #ffffff;
    border-radius: 8px;
}

.title-mini {
    padding: 10px 24px 8px 24px;
    font-weight: 600;
    font-size: 14px;
    color: #4b4b4b;
    line-height: 21px;
}

.button {
    font-weight: 600;
    font-size: 14px;
    border-radius: 4px !important;
}

::-webkit-scrollbar {
    width: 3.5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f8f8f8;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #babfc7;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #4b4b4b;
}

.dropdown-border {
    border-color: #4c78ee !important;
    border-radius: 8px !important;
    border: 1px solid;
    max-height: 500px;
    overflow-y: scroll;
}
.custom-select .ant-select-selection-item {
    background-color: #d9d9d9 !important; /* Устанавливаем желаемый цвет фона для выбранного элемента */
}
.custom-select .ant-select-selector {
    background-color: #d9d9d9 !important; /* Устанавливаем желаемый цвет заднего фона для всей области */
}
.back-button-header {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #babfc7;
    gap: 11px;
    cursor: pointer;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.form .ant-form-item-label > label {
    font-weight: 600;
    font-size: 12px !important;
    color: #4b4b4b;
}

.form .ant-form-item {
    margin-bottom: 20px !important;
}

.form .no-mb {
    margin-bottom: 0px !important;
}

.form .ant-form-item-explain-error {
    font-size: 12px;
}

.form .ant-input:not(textarea) {
    height: 30px !important;
    border: 1px solid #babfc7;
}

.collapse {
    overflow: hidden;
    transition: height 0.4s ease-in-out;
}
