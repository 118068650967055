.modal-wrapper .ant-form-item-label > label {
    font-weight: 600;
    font-size: 12px !important;
    color: #4b4b4b;
}

.modal-wrapper .ant-form-item {
    margin-bottom: 20px !important;
}

.modal-wrapper .no-mb {
    margin-bottom: 0px !important;
}

.modal-wrapper .ant-form-item-explain-error {
    font-size: 12px;
}

.modal-wrapper .ant-input:not(textarea) {
    height: 30px !important;
    border: 1px solid #babfc7;
}

.modal-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #4b4b4b;
}

.modal-subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #babfc7;
}

.modal-form-label {
    font-weight: 600;
    font-size: 12px;
    color: #4b4b4b;
}

.modal-switch-label.active {
    color: #4c78ee;
}

.modal-switch-wrapper {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-bottom: 15px;
}

.add-photo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(76, 120, 238, 0.12);
    width: 90px;
    height: 120px;
    cursor: pointer;
    margin-top: 9px;
}
