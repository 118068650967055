.analytics-discount-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #4b4b4b;
}

.analytics-discount-title {
    font-weight: 600;
    font-size: 12px;
}

.analytics-discount-amount {
    border: 1px solid #babfc7;
    border-radius: 4px;
    padding: 3px 12px;
}

.analytics-actions-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #82868b;
    gap: 20px;
}

.analytics-actions-wrapper div {
    display: flex;
    align-items: center;
    gap: 4px;
}

.analytics-row-container {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    width: 100%;
    border-radius: 8px;
}

.gray {
    background: rgba(130, 134, 139, 0.2);
}

.analytics-row {
    display: flex;
    gap: 1px;
    max-width: 100%;
}

.analytics-col {
    text-align: center;
    font-size: 12px;
    color: #4b4b4b;
    flex-grow: 1;

    position: relative;
}
.hover-info-frame-wrap {
    min-width: 100px;
    width: auto;
    max-width: 400px;
    min-height: 100px;
    background-color: #fff;
    position: absolute;
    top: 100%;
    left: 0;
    border: 1px solid #ccc;
    padding: 5px 15px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    z-index: 999;
    white-space: nowrap;
    overflow: hidden;

}
.hover-info-frame-item {

}


.analytics-col div {
    min-height: 32px;
    line-height: 32px;
}

.analytics-col div span {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
}

.analytics-col-title {
    font-weight: 600;
    color: #4b4b4b !important;
    /* font-variant-numeric: tabular-nums; */
}

.analytics-right-wrapper {
    flex-grow: 1;
    max-width: calc(100% - 108px);
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.ads-statistics-right-wrapper {
    flex-grow: 1;
    max-width: calc(100% - 108px);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.analytics-col-abc {
    text-align: center;
    background-color: #e6e7e8;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #ffffff;
    border-top: 2px solid white;
    border-bottom: none;
}

.analytics-right-wrapper :first-child :last-child {
    border-top-right-radius: 8px;
}

.analytics-right-wrapper :last-child :last-child:not(img) {
    border-bottom-right-radius: 8px;
}

.analytics-header-dropdown {
    width: 100%;
    background-color: #ffffff;
    height: 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    font-weight: 600;
    font-size: 12px;
    color: #82868b;
    cursor: pointer;
}

.analytics-header-dropdown-v2 {
    width: 90px;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    color: #82868b;
    cursor: pointer;
}


.analytics-header-dropdown-v3 {
    border: 1px solid #d7d7d7;
    border-radius: 6px;
    padding: 0 10px;
    width: 200px;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    color: #82868b;
    cursor: pointer;
}

.analytics-header-dropdown-v4 {
    border: 1px solid #d7d7d7;
    border-radius: 6px;
    padding: 0 10px;
    width: 200px;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    color: #82868b;
    cursor: pointer;
}

.analytics-header-dropdown-v3:hover {
    border: 1px solid #7182ff;
}

.analytics-header-dropdown-v4:hover {
    border: 1px solid #7182ff;
}

.analytic-dropdown-input {
    padding: 0 !important;
    border: none !important;
}

.filter-menu {
    border-radius: 8px;
    border: 1px solid #4c78ee !important;
    font-size: 14px !important;
    line-height: 21px !important;
    color: #4b4b4b !important;
    width: 218px;
    top: 25px;
    left: -150%;
    position: absolute;
    line-height: 1.5714285714285714 !important;
    z-index: 1;
    transition: all linear 0.1s;
    transition-delay: 0.1s;
    overflow: hidden;
    border: 1px solid #4c78ee;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
}

.filter-menu .ant-menu-inline {
    border-inline-end: none !important;
}
.filter-menu .ant-menu-item .ant-menu-submenu {
    height: unset !important;
    line-height: unset !important;
    padding: 8px;
}

.filter-menu .ant-menu-submenu-title {
    font-size: 14px !important;
    line-height: 21px !important;
    color: #4b4b4b !important;
    padding-left: 10px !important;
}

.filter-menu .ant-menu-submenu-title:active {
    background-color: #ffffff !important;
}

.filter-menu .ant-menu-sub.ant-menu-inline {
    background-color: #ffffff !important;
}

.filter-menu .ant-menu-sub .ant-menu-item-selected {
    background: rgba(76, 120, 238, 0.12) !important;
}

.filter-menu .ant-menu-item-selected::before {
    content: none;
}

.filter-menu .ant-menu-submenu-arrow {
    color: #4c78ee !important;
}

.filter-menu .ant-menu-item {
    padding-left: 10px !important;
}

.filter-menu .ant-menu-inline .ant-menu-item {
    height: 35px !important;
}
.filter-menu .ant-menu-submenu-title {
    height: 35px !important;
}

.filter-menu-opened {
    max-height: 2000px !important;
}

.filter-menu-closed {
    max-height: 0;
    overflow: hidden;
    border-color: white !important;
}

.keyword-chart-wrapper {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 13px 20px;
}

.keyword-date-select-button {
    cursor: pointer;
    transition: all 0.2s;
}

.keyword-date-select-button-active {
    color: #4c78ee;
}

.keyword-date-select-button:hover {
    color: #4c78ee;
}

.keyword-chart-message-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 0;
}

.keyword-chart-message {
    font-weight: 500;
    font-size: 21px;
    line-height: 26px;
    text-align: center;
    color: #babfc7;
    margin-top: 19px;
}

.keyword-table-wrapper {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    margin-top: 20px;
}

.article-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #4b4b4b;
    margin-left: 10px;
}

.article-date {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #4c78ee;
}

.article-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #82868b;
}

.article-profile-name {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #4b4b4b;
}

.article-profile-occupation {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #82868b;
}

.article-line {
    position: absolute;
    top: 20px;
    left: 9px;
}

.articles-container {
    height: 298px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.send-article-wrapper {
    margin-top: 20px;
}

.send-article-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #4b4b4b;
    margin-bottom: 15px;
}

.analytics-cashback-button {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #82868b;
    height: 36px;
    display: flex;
    align-items: center;
    background-color: #ffffff;
    justify-content: center;
    padding-top: 1px;
    cursor: pointer;
}

.analytics-cashback-button.active {
    color: #4c78ee;
}

.analytics-cashback-button:hover {
    color: #4c78ee;
}

.keyword-table-col {
    text-align: center;
}

.keyword-table
    .keyword-disable-row-selection-second
    .ant-table-selection-column {
    background: rgb(248, 250, 254) !important;
}

.keyword-table .ant-table-row-selected > td {
    background: unset !important;
}

.keyword-disable-row-selection-second:hover .ant-table-selection-column {
    background: rgb(248, 250, 254) !important;
}

.keyword-table-first-row {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #4b4b4b;
}

.keyword-table-second-row {
    background: rgb(248, 250, 254) !important;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #4b4b4b;
    height: 100%;
    align-items: center;
    display: flex;
}

.keyword-disable-row-selection-second .ant-table-selection-column {
    background: rgba(76, 120, 238, 0.04);
}

.keyword-disable-row-selection-first .ant-table-selection-column label {
    display: none;
}

.keyword-disable-row-selection-second .ant-table-selection-column label {
    display: none;
}

.keyword-disable-row-selection-first:hover > td {
    background: white !important;
}

.keyword-add-dropdown {
    border: 1px solid #4c78ee;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 23px 13px;
    width: 230px;
    position: absolute;
    z-index: 99999;
    top: 50px;
    background: white;
    max-height: 500px;
    transition: all 0.5s;
    overflow: hidden;
}

.keyword-add-dropdown label {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #4b4b4b;
    padding-bottom: 5px;
}

.keyword-add-dropdown input {
    height: 30px;
}

.keyword-add-dropdown .ant-form-item {
    margin-bottom: 0;
}

.keyword-add-dropdown-closed {
    max-height: 0px;
    padding: 0px;
    border: none;
}

.analytic-rows-container > div {
    margin-top: 20px;
}

.analytic-rows-container > div:first-child {
    margin-top: 0;
}

.analytic-rows-container > div:nth-child(2) {
    margin-top: 0;
}

.analytics-col-text {
    overflow: hidden;
}

.hover-left {
  transform: translateX(-100%);
}

.hover-right {
  transform: translateX(0%);
}

.cashback-table th {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #4b4b4b;
}

.cashback-table td {
    text-align: center;
    font-size: 12px;
}

.analytics-spp-input {
    padding: 3px 12px;
    width: 60px;
}

.cashbacks-participants-header-container {
    background: rgb(234, 239, 253);
    height: 32px;
    display: flex;
}

.cashbacks-participants-heading {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #4b4b4b;
    border-right: 1px solid #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.cashbacks-participants-body-container {
    background: #ffffff;
    display: flex;
}

.cashbacks-participants-col {
    font-size: 12px;
    line-height: 14px;
    color: #4b4b4b;
    border-width: 0px 1px 1px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
}

.participants-yes-no-container {
    display: flex;
    width: 100%;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    align-items: center;
    color: #ffffff;
    height: 100%;
}

.participants-agreed-col {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.cashback-table-row {
    cursor: pointer;
}

.container-key-word-switch{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: relative;
}

.container-key-word-switch-side-one{
    position: absolute;
    left: 350px;
}
.container-key-word-switch-side-two{
    position: absolute;
    right: 220px;
}