.storage-tabs {
    display: flex;
    gap: 14px;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #82868b;
}

.storage-tabs div {
    cursor: pointer;
    transition: color 0.3s linear;
}

.storage-tabs div:hover {
    color: #4c78ee;
}

.storage-tabs .tab-active {
    color: #4c78ee;
}

/*.ant-table-selection-column .ant-checkbox-wrapper {*/
/*    display: flex;*/
/*    align-items: center;*/
/*    height: 100%;*/
/*    padding: 0 8px; !* Добавьте отступы вокруг чекбокса по вашему усмотрению *!*/
/*}*/

.product-arrival-table th {
    padding: 0 !important;
    text-align: center !important;
    height: 32px !important;
}

.product-arrival-table td {
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #4b4b4b;
    height: 32px;
    border-width: 0px 1px 1px 0px;
}

.additional-deliveries-table table {
    border-radius: 0 !important;
}

.additional-deliveries-table th {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #4b4b4b;
    background: rgba(76, 120, 238, 0.12) !important;
    padding: 7px 0 !important;
}

.additional-deliveries-table td {
    text-align: center;
    height: 40px;
}

.additional-deliveries-table
.ant-table-container
table
> thead
> tr:first-child
> *:first-child {
    border-start-start-radius: 0 !important;
}

.additional-deliveries-table
.ant-table-container
table
> thead
> tr:first-child
> *:last-child {
    border-start-end-radius: 0 !important;
}

.additional-deliveries-table td {
    border-width: 0px 1px 1px 0px;
    border-style: solid;
    border-color: rgba(76, 120, 238, 0.12);
}

.additional-deliveries-table table > tbody > tr td:first-child {
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: rgba(76, 120, 238, 0.12);
}

.shipments-formation-row-header {
    background: #f8f8f8;
    font-weight: 600;
    line-height: 14px;
    color: #4b4b4b;
    min-height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 12px;
}

@media all and (max-width: 1250px) {
    .shipments-formation-row-header {
        height: 40px;
    }
}

.shipments-table-highlighted {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #4c78ee;
}

.shipments-table-text {
    font-size: 10px;
    line-height: 12px;
    color: #4b4b4b;
    margin-bottom: 4px;
}

.shipments-table-col {
    padding: 4px 0 0 11px;
    text-align: center;
}

.shipments-table-product-col {
    display: flex;
    padding: 11px 10px;
    gap: 10px;
}

.shipments-table-product-col > div > div {
    margin-bottom: 4px;
}

.shipments-size-row {
    background: rgba(76, 120, 238, 0.12);
    padding: 14px 30px;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #4b4b4b;
}

.shipment-size-table th {
    font-weight: 600;
    background: rgba(76, 120, 238, 0.12) !important;
    color: #4b4b4b;
    height: 32px;
    line-height: 14px;
    font-size: 12px;
}

.shipment-size-table td {
    text-align: center;
    font-size: 12px;
    line-height: 14px;
    color: #4b4b4b;
    height: 25px;
    border-width: 0px 1px 1px 0px;
    border-style: solid;
    border-color: rgba(76, 120, 238, 0.12);
}

.shipment-size-table table > tbody > tr td:first-child {
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: rgba(76, 120, 238, 0.12);
}

.shipment-size-table
.ant-table-container
table
> thead
> tr:first-child
> *:first-child {
    border-start-start-radius: 0 !important;
}

.shipment-size-table
.ant-table-container
table
> thead
> tr:first-child
> *:last-child {
    border-start-end-radius: 0 !important;
}


.saved-invoices-wrap {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    padding: 20px;
}

.saved-invoices-item {
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    width: 100%;
    padding: 20px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.saved-invoices-item-left {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.saved-invoices-item-title {
    margin-left: 10px;

    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #4B4B4B;
}

.saved-invoices-item-right {
    display: flex;
    gap: 20px;
}

.storage-edit-kiz-files {
    display: flex;
    flex-direction: column;
    gap: 20px
}

.storage-edit-kiz-files-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.storage-edit-kiz-files-item-title {
    color: #4C78EE;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
}

.storage-edit-kiz-files-item-right {
    display: flex;
    align-items: center;
    gap: 10px;
}

.storage-edit-kiz-files-item-right-item {
    color: #4C78EE;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    cursor: pointer;

    display: flex;
    gap: 10px;
}

.table-header-select-product{
    border: 1px solid #babfc7 !important;
    border-radius: 4px !important;
}
