.product-extra-nav {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.product-extra-nav-buttons {
    display: flex;
    align-items: center;
    gap: 10px;
}

.product-extra-nav-items {
    padding: 0 15px;
    display: flex;
    gap: 10px;
}

.product-extra-nav-item a {
    cursor: pointer;
    color: #000;
}

.product-extra-nav-item p {
    cursor: pointer;
    color: #000;
}

.product-extra-nav-item-active a {
    color: #4c78ee;
}

.product-extra-nav-item-active p {
    color: #4c78ee;
}

.edit-product-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}

.edit-product-item-analytic {
    min-height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    background: #FFFFFF;
    border: 1px solid #BABFC7;
    border-radius: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #4B4B4B;
}

.edit-product-item-analytic-empty {
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #4B4B4B;
}

.edit-product-item-analytic-bucket {
    cursor: pointer;
}

.products-header-dropdown {
    font-family: 'Montserrat', sans-serif;
    background: #FFFFFF;
    border: 1px solid #BABFC7 !important;
    border-radius: 4px;
    height: 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #BABFC7;
}

.products-edit-wrap {
    display: flex;
    justify-content: space-between;
}

.products-edit {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 10px;
    color: #4B4B4B;
    cursor: pointer;
}

.products-edit-active {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 10px;
    color: #4c78ee;
    cursor: pointer;
}

.products-edit-buttons-wrap {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.products-edit-button-save {
    font-weight: 600;
    font-size: 14px;
    border-radius: 4px !important;

}

.products-edit-button-exit {
    font-weight: 600;
    font-size: 14px;
    border-radius: 4px !important;
    color: #4C78EE;
    background-color: #fff;
    border: 1px solid #4C78EE;
}

.products-edit-button-exit:hover {
    color: #4C78EE !important;
    background-color: #f1f1f1 !important;
    border: 1px solid #4C78EE !important;
}

.plus-minus-wrap {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.plus-minus-wrap-title{
    color: #000;
    font-weight: 600
}

.plus-minus-wrap-phrases {
    min-height: 400px;
    padding: 10px;
    border: 1px solid rgba(163, 163, 163, 0.5);
    border-radius: 10px;
    max-height: 200px;
    overflow-y: auto;

}

.plus-minus-wrap-phrases-item{
    cursor: pointer
}
