.header {
    padding: 17px 21px;
}

.header-mob {
    display: none;
    background-color: #5C4E73;
}

.company-title-container {
    display: flex;
    align-items: center;
    gap: 12px;
}

.company-title-container-mob {
    display: flex;
    align-items: center;
    gap: 12px;
    background-color: #5C4E73;
}

.company-title {
    font-weight: 700;
    font-size: 24px;
}

.header-actions-container {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: end;
}

.header-burger {
    cursor: pointer;
    display: none;
}

.header-desc-bar {

}

.header-mob-bar {
    display: none;
}

.header-mob-close {
    cursor: pointer;
    margin-top: 10px;
    display: flex;
    gap: 5px;
    align-items: center;
}

.header-mob-close h2 {
    color: #4B4B4B;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.5px;
}

@media (max-width: 1350px) {
    .header {
        display: none;
    }

    .header-mob {
        display: block;
        padding: 10px 21px;
    }

    .company-title-container {
        display: none;
    }

    .header-burger {
        cursor: pointer;
        display: block;
    }

    .header-desc-bar {
        display: none;
    }

    .header-mob-bar {
        display: block;
    }
}