/* SIDENAV MENU */

.ant-menu-light {
    color: #4b4b4b;
}

.ant-menu-item-group-title {
    font-weight: 600 !important;
    font-size: 10px !important;
    line-height: 12px !important;
    color: #747474 !important;
    text-transform: uppercase !important;
}

.ant-menu-title-content {
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    display: flex;
    align-items: center;
}

.hide-group-title .ant-menu-item-group-title {
    display: none;
}

.ant-menu-item {
    border-radius: 0 !important;
    margin-left: -1px;
}

.ant-menu-light .ant-menu-item-selected {
    background: linear-gradient(
        90deg,
        rgba(76, 120, 238, 0.18) 0%,
        rgba(76, 120, 238, 0) 100%
    ) !important;
    border-radius: 0 !important;
}


.ant-menu-light .ant-menu-item-selected::before {
    content: " ";
    width: 5px;
    height: 5px;
    background: #4c78ee;
    height: 25.6px;
    position: absolute;
    left: 0.5px;
    border-radius: 0px 8px 8px 0px;
    top: 50%;
    transform: translateY(-50%);
}

.ant-dropdown {
    /* border-color: #4c78ee !important;
    border-radius: 8px !important;
    border: 1px solid; */
}

.ant-modal-content {
    padding: 0 !important;
}

.ant-tooltip {
    max-width: 317px !important;
}

.ant-tooltip-arrow {
    display: none !important;
}

.ant-tooltip-content {
    border: 1px solid #4c78ee !important;
    border-radius: 8px !important;
}

.ant-tooltip-inner {
    /*padding: 13px 10px !important;*/
    font-weight: 400;
    font-size: 12px;
    color: #4b4b4b !important;
    background-color: #ffffff !important;
}

.ant-radio-group {
    display: flex !important;
    gap: 2px !important;
}

.ant-radio-button-wrapper {
    border-radius: 0 !important;
    border: none !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    color: #82868b;
    height: 36px !important;
    padding-inline: 5px !important;
}

.ant-radio-button-wrapper::before {
    content: none !important;
}

.ant-select-dropdown
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #4c78ee;
    color: #ffffff;
}

.ant-select-multiple .ant-select-selection-item {
    background: rgba(76, 120, 238, 0.12) !important;
    border-radius: 4px !important;
}

@media (max-width: 1350px) {

    .menu-item-bckg:hover{
        background: linear-gradient(113deg, rgba(217, 217, 217, 0.60) 0%, rgba(217, 217, 217, 0.00) 100%);
        transition: background-position 0.5s;
    }
}
