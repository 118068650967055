.login-title {
  font-weight: 700;
  font-size: 24px;
  color: #4c78ee;
}

.form-label {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}
