.document-help-text {
    color: #4B4B4B;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 10px 0 20px;
}

.document-page-title-wrap {
    display: flex;
    flex-direction: row;
    padding: 0 20px
}

.document-page-title-elem {
    width: 200px;
    min-width: 200px;
}

.document-page-items {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 16px;
    width: 100%;
}

.document-page-items-elem {
    height: 57px;
    width: 100%;
    border-radius: 8px;
    background: #FFF;

    /* тень_2 */
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.30);

    display: flex;
    align-items: center;
    padding: 0 21px
}

.document-page-items-users {
    height: 75px;
    width: 100%;
    border-radius: 8px;
    background: #FFF;

    /* тень_2 */
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.30);

    display: flex;
    align-items: center;
    padding: 0 21px
}

.item-title{
    font-size: 1.1em; 
    font-weight: 700;
    color: #333;
    transition: all .3s;
}
.item-title:hover{
    color: #6678ee;
}

.document-page-items-elem-type {
    display: flex;
    align-items: center;

    color: #4B4B4B;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 10px 10px 10px 0;
}

.document-page-items-elem-name {
    color: #4B4B4B;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 10px 10px 10px 0;
}

.document-page-items-elem-users {
    color: #4B4B4B;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 5px 5px 10px 0;
    display: flex;
    flex-direction: column;
    gap: 0.1rem
}

.document-page-items-elem-number {
    color: #4B4B4B;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 10px 10px 10px 0;

}

.document-page-items-elem-date {
    color: #4B4B4B;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 10px 10px 10px 0;

}

.document-page-title-elem-button {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 10px 10px 10px 0;

}