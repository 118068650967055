.sidenav-button {
    cursor: pointer;
    transition: all 0.3s;
}

.sidenav-button:hover {
    filter: invert(44%) sepia(28%) saturate(6321%) hue-rotate(211deg) brightness(100%) contrast(88%);
}

.sidenav-button-item:hover {
    background-color: transparent !important;
}

.sidenav-offset {
    margin-top: 450px;
}

.menu-not-collapsed .ant-menu-title-content span:not(.anticon) {
    white-space: pre-wrap;
}

@media (max-width: 1350px) {
    .sidenav-button {
        display: none;
    }

}