.shipments-page tbody .ant-table-cell {
    height: auto;
    text-align: center;
    padding: 0px 0 !important;
    border-radius: 0 !important;


}

.shipments-page {
    td {
        font-size: 12px !important;
        font-weight: 400 !important;
    }
}


.shipments-table {

    &-header {
        padding: 3px 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: rgb(250, 250, 250);

        p {
            &:first-child {
                width: 10%;
            }

            padding: 3px;
            margin: 0;
            color: rgba(0, 0, 0, 0.88);
            font-size: 12px;
            font-weight: 600;
            display: block;
            width: 9%;
            text-align: center;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                width: 1px;
                height: 80%;
                background-color: rgba(0, 0, 0, 0.1);
            }

            &:last-child {
                &::after {
                    width: 0;
                }
            }
        }
    }

    &-row {
        padding: 0 1.1%;
        display: flex;

        &:hover {
            background-color: rgba(215, 215, 215, 0.1);
        }

        border-bottom: 1px solid rgba(215, 215, 215, 0.4);

        &-left {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 8%;
            max-width: 8%;

            img {
                margin: 10px;
                width: 80%;
                height: auto;
            }

            button {
                cursor: pointer;
                border-radius: 5px;
                background-color: #fff;
                border: 1px solid #cccccc;
            }
        }

        &-main {
            width: 92%;
            display: flex;
            flex-direction: column;

            table {
                border-spacing: 0 !important;

            }


            &-data {
                width: 100%;
                display: flex;
                border-bottom: 1px solid #cccccc;

                p {
                    padding: 2px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: rgba(0, 0, 0, 0.88);
                    font-size: 12px;
                    font-weight: 400;
                    width: 10%;
                    text-align: center;
                    position: relative;

                }
            }

            &-sizes {
                flex: 1;
                margin-top: 5px;

                &-row {
                    width: 100%;
                    display: flex;

                    &:hover {
                        background-color: rgba(187, 187, 187, 0.2);
                    }

                    p {
                        margin: 0px;
                        padding: 0px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: rgba(0, 0, 0, 0.88);
                        font-size: 12px;
                        font-weight: 400;
                        width: 10%;
                        text-align: center;
                        position: relative;

                    }
                }
            }
        }
    }
}
