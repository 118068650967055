.finance-page {
    background-color: #5c4e73;
    min-height: 100%;
}

.financial-results-table {
    font-size: 12px;
    /*text-align: center;*/
    color: #4b4b4b;
}

.fin-result-wrapper {
    display: none;
}

.fin-result-wrapper-mob {
    display: block;
    background-color: #5c4e73;
}

.fin-result-range-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    min-width: 200px;
    height: 50px;
    color: #fff
}

.fin-result-range-text {
    color: #fff;
    font-size: 16px;
    padding: 0 10px;
}

.financial-results-table .header {
    font-weight: 600;
    background: rgba(76, 120, 238, 0.14);
    padding: 0 24px;
}

.financial-results-table .default {
    font-weight: 600;
    padding: 0 24px;
}

.financial-results-show-more {
    padding: 10px;
    background: rgb(220, 220, 220);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    text-align: center;
    cursor: pointer;
}

.financial-results-time-choose {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.financial-results-time-choose-item {
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;

    color: #BABFC7;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
}

.financial-results-time-choose-item-active {
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;

    color: #4B4B4B;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
}

.financial-results-time-circle-choose-item {
    width: 10px;
    height: 10px;
    border-radius: 50px;
    background: #BABFC7;
}

.financial-results-time-choose-item-circle-active {
    width: 10px;
    height: 10px;
    border-radius: 50px;
    background: #594F70;
}


.financial-results-time-choose {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.finance-page {
    background-color: #5c4e73;
}

.fin-result-wrapper {
    display: none;
}

.fin-result-wrapper-mob {
    display: block;
    background-color: #5c4e73;
}

.financial-results-time-choose-mob {
    margin-top: 5px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    grid-gap: 5px;
}

.financial-results-time-choose-item-mob {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    border-radius: 4px;
    background: rgba(217, 217, 217, 0.20);
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);

    color: #A6A6A6;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;

    cursor: pointer;
}

.financial-results-time-choose-item-active-mob {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    border-radius: 4px;
    background: #9BB913;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);

    color: rgba(230, 231, 232, 1);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;

    cursor: pointer;
}

.financial-results-time-circle-choose-item-mob {
    display: block;
}

.financial-results-time-choose-item-circle-active-mob {
    display: block;
}

.range-picker {
    font-size: 12px;
    font-family: "Montserrat", sans-serif;
}

.range-picker input {
    font-size: 12px;
    font-family: "Montserrat", sans-serif;
}

.ant-picker-input input {
    font-size: 12px !important; /* Измените значение размера шрифта по своему усмотрению */
}


.fina-result-main-card {
    margin-bottom: 5px;
    border-radius: 5px;
    padding: 5px 10px;
    width: 100%;
    min-height: 100px;
    background-color: #D9D9D9;
    display: flex;
    flex-direction: column;
}

.fina-result-main-card-one {
    margin-bottom: 5px;
    border-radius: 5px;
    padding: 5px 10px;
    width: 100%;
    min-height: 67px;
    background-color: #D9D9D9;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}


.fina-result-main-card-solo {
    margin-bottom: 5px;
    border-radius: 5px;
    padding: 17px 20px;
    width: 100%;
    min-height: 113px;
    background-color: #9BB913;
    display: flex;
    flex-direction: column;
}

.fina-result-main-title {
    color: #171717;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 80%
}

.fina-result-main-title-solo {
    color: #171717;
    font-size: 22px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.fina-result-main-card-value {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;

    color: #171717;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.fina-result-main-card-diff {
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.fina-result-main-card-value-solo {
    margin-top: 14px;
    color: #171717;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.fina-result-main-card-roi {

    color: #171717;
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.fina-result-second-card-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 5px;
}

.fina-result-second-card-wrap-desc {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.grid-item {
    background-color: #f2f2f2; /* Цвет фона блока, измените по своему усмотрению */
    padding: 10px; /* Измените отступы внутри блока по своему усмотрению */
}

.p-timeline-event-connector {
    min-height: 1px;
    background-color: #d9d9d9 !important;
}

.icon-inside-chart-wrap {
    padding: 5px;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    background-color: #fff;

    display: flex;
    justify-content: center;
    align-items: center;
}

.info-chart-content {
    margin-right: 30px !important;
    min-height: 100px !important;;
    min-width: 150px !important;;
}

.icon-inside-chart {
    background-size: cover;
}

.p-timeline-event-opposite {
    display: none
}

.p-card .p-card-content {
    padding: 0 !important;
}

.p-card-body {
    padding: 0 !important;
}

.fin-download-modal-item-name {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #4B4B4B;
    cursor: pointer;
}

.fin-download-modal-item-wrap {
    display: flex;
    flex-direction: column;
    gap: 35px;
    margin-bottom: 20px;
}

.fin-download-modal {
    display: flex;
    align-items: center;
    gap: 10px;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #2162d4;

    cursor: pointer;
}

.info-chart-wrap {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 40px auto;
}

.info-chart-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.info-chart-item-top {
    display: flex;
    gap: 0px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.info-chart-item-bottom {
    margin: 0 20px;
}

.info-chart-item-icon {

}

.info-chart-item-block {
    width: 170px;
    height: 80px;
    background-color: #D9D9D9;
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    color: #4A5056;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}


.info-chart-wrap-desc {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.info-chart-desc-item {
    height: 55vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.info-chart-desc-item-left {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.info-chart-desc-item-left-blob {
    width: 170px;
    height: 80px;
    visibility: hidden;

}

.info-chart-desc-item-left-block {
    /*width: 170px;*/
    /*height: 80px;*/
    background-color: #D9D9D9;
    border-radius: 10px;
    padding: 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    color: #4A5056;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    width: 10vw;
    height: 10vh;
}

.info-chart-desc-item-left-dots {
    position: absolute;
    right: -2.2vw;
    width: 4.5vw;
    height: 4.5vh;
}

.info-chart-desc-item-horizontnal-dots {
    width: 5vw;
    height: 5vh;
}

.info-chart-desc-item-horizontnal-dots-mob {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 0px;
    right: 100px;
    z-index: 999;
}

.info-chart-desc-item-horizontnal-dots-bottom {
    width: 5vw;
    height: 5vh;
}

.info-chart-desc-item-left-info-block{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 34.5vh
}
.info-chart-desc-item-left-info-block-bottom{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 34.5vh
}
@media (max-width: 1350px) {
    .p-timeline-event-connector {
        min-height: 50px;
    }

    .info-chart-content {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0px !important;
        min-height: 60px !important;
        min-width: 0px !important;
    }

    .ant-picker-panels {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .info-chart-wrap {
        display: flex;
    }

    .info-chart-mob {
        display: flex;
    }

    .info-chart-wrap-desc {
        display: none;
    }
}

@media (max-width: 450px) {
    .fin-res-callendar {
        display: none
    }
}
