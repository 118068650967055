.product-card {
	text-align: center;
	width: 600px;
	min-width: 437px;
	border-radius: 8px 0 0 8px;
	height: 100%;

	.product-details {
		display: flex;
		flex-wrap: wrap;
		background: #d0e0e3;
		height: 100%;
		margin: 0 1px;

		.product-image {
			width: 49%;
			a {
				img {
					width: 100%;
					max-width: 213px;
					max-height: 284px;
					aspect-ratio: 3 / 4;
				}
				svg {
					width: 100%;
					max-width: 213px;
					max-height: 260px;
					aspect-ratio: 3 / 4;
				}
			}
		}

		.product-info {
			width: 51%;
			display: flex;
			flex-direction: column;
			gap: 0.7px;
			margin-right: -5px;

			.info-row {
				display: flex;

				&.border-bottom {
					border-bottom: 1px solid #000;
				}

				&.border-top {
					border-top: 1px solid #000;
				}

				.analytics-col {
					width: 50%;
					text-align: center;

					&.border-right {
						border-right: 1px solid #000;
						width: 40%;
					}

					.empty-row {
						min-height: 25px;
						max-height: 25px;
					}

					.highlight {
						font-weight: 700;
						color: #000000cc;
						display: flex;
						align-items: center;
						justify-content: center;
						min-height: 25px;
						max-height: 25px;
					}

					.stock-data,
					.order-data,
					.boost-data,
					.stock-duration {
						background-color: #d5a6bd;
						font-weight: 700;
						color: #000;
						display: flex;
						align-items: center;
						justify-content: center;
						height: 50%;
						line-height: 140%;
						min-height: 25px;
						max-height: 25px;
					}

					.underline {
						text-decoration: underline;
					}
				}

				.yellow {
					background: #ffe5a0;
				}
				.grey {
					background: #e6e6e6;
				}
				.light-yellow {
					background: #fff2cc;
					background-color: #fff2cc !important;
				}
				.transparent {
					background: transparent;
					background-color: transparent !important;
				}

				.comments {
					font-weight: 700;
					color: #000;
					font-size: 12px;
				}

				.centered-section {
					display: flex;
					align-items: center;
					justify-content: center;
				}

				.half-row {
					border-bottom: 1px solid #000;
				}
			}
		}
	}
}

.ads-data {
	display: flex;
	flex-direction: column;
	.ads-data-row {
		display: flex !important;
		align-items: center;
		justify-content: space-between;
		border-top: 1px solid #000;
		min-height: 25px;
		max-height: 25px;
		&:last-child {
			border-bottom: 1px solid #000;
		}

		.ads-status {
			width: 50%;
			border-right: 1px solid #000;
			width: 45%;
			min-height: 25px;
			max-height: 25px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.ads-type {
			width: 50%;
			font-weight: 700;
			color: #000;
			min-height: 25px;
			max-height: 25px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}
