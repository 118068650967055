.account-input-wrapper {
    box-shadow: inset 0px 1px 0px #f0f0f0;
    padding-bottom: 20px;
}

.account-input-wrapper .ant-form-item {
    margin-bottom: 0;
}

.account-input-wrapper .ant-input-affix-wrapper {
    height: 40px !important;
    border: 1px solid #babfc7;
}

.avatar-wrapper {
    display: flex;
    text-align: center;
    box-shadow: inset 0px 1px 0px #f0f0f0;
    padding: 18px 70px;
}

.avatar-edit-button {
    border: 1px solid #babfc7;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 4px;
    font-weight: 600;
    color: #4b4b4b;
}

.account-tab-button {
    font-weight: 600;
    font-size: 12px;
    color: #82868b;
}

.account-tab-button-active {
    font-weight: 600;
    font-size: 12px;
    color: #4c78ee;
}

.account-table-password-column {
    background: transparent;
    border: none;
    font-size: 30px;
    width: 100%;
    text-align: center;
    pointer-events: none;
}

.account-table-wrapper td {
    text-align: center;
    font-size: 12px;
}

.account-table-wrapper th {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
}

.account-table-wrapper .ant-table-filter-column {
    justify-content: center;
}

.account-table-wrapper .ant-table-filter-column {
    display: flex;
}

.account-add-button {
    font-weight: 600;
    font-size: 12px;
    border: 1px solid #4c78ee;
    border-radius: 4px;
    width: 190px;
}
