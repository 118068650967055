.mirror-filter-dropdown {
    padding: 8px 0px;
    width: 235px;
}

.mirror-filter-dropdown .ant-input-prefix {
    margin-right: 10px;
}

.mirror-filter-dropdown .ant-input-affix-wrapper {
    padding: 5px 12px;
    width: 100%;
    border: none;
}

/*.mirror-filter-dropdown .ant-input-affix-wrapper:hover {*/
/*    border: none !important;*/
/*    border-inline-width: 0px !important;*/
/*    box-shadow: none !important;*/
/*}*/

/*.mirror-filter-dropdown .ant-input-affix-wrapper-focused {*/
/*    border: none !important;*/
/*    border-inline-width: 0px !important;*/
/*    box-shadow: none !important;*/
/*}*/

.filter-sort {
    display: flex;
    padding: 8px 12px;
    gap: 12px;
    line-height: 21px;
    transition: all linear 0.3s;
    cursor: pointer;
}

.filter-sort:hover {
    background: rgba(76, 120, 238, 0.12);
}

.filter-sort-active {
    background: rgba(76, 120, 238, 0.12);
}

.mirror-filter-dropdown .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-inline-start: 0;
}

.mirror-filter-rangepicker-wrapper {
    position: relative;
}

.mirror-filter-rangepicker-wrapper .ant-picker {
    display: none;
}

.testix {
    border-width: 0px !important;
}

.testix::before {
    content: none !important;
}

.mirror-filter-rangepicker-wrapper {
    border: none !important;
}

.budget-input-title {
    font-size: 12px;
    line-height: 14px;
    color: #babfc7;
}

.budget-input-container {
    display: flex;
    align-items: center;
    gap: 13px;
    padding-top: 10px;
}

.budget-input {
    border: none !important;
    font-size: 16px;
    line-height: 20px;
    color: #4b4b4b !important;
    padding: 0;
}

.budget-input-container .ant-input {
    border: none !important;
}

.budget-input .ant-input-suffix {
    padding-top: 1px !important;
}

.budget-input-edit-button {
    font-size: 10px;
    cursor: pointer;
}

.entrance-rate-label {
    font-size: 10px;
    line-height: 12px;
    color: #babfc7;
    margin-bottom: 7px;
}

.entrance-rate-selected {
    font-size: 16px;
    line-height: 20px;
    color: #4c78ee;
}

.entrance-rate-selected span {
    font-weight: 600;
}

.entrance-rate {
    font-size: 16px;
    line-height: 20px;
    color: #babfc7;
}

.entrance-rate span {
    font-weight: 600;
}

.datepick-wrapper {
    margin-top: 20px;
}

.entrance-rate-wrapper {
    margin-top: 20px;
}

.datepick-container {
    display: flex;
    gap: 13px;
    margin-top: 10px;
}

.keyword-input-container {
    display: flex;
    flex-direction: column;
    border: 1px solid #babfc7;
    border-radius: 4px;
    padding: 10px 8px;
    margin-top: 10px;
    height: 200px;
    overflow-y: scroll;
}

.keyword-input-container .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-inline-start: 0px;
}

.keyword-input-container .ant-checkbox-wrapper {
    margin-top: 8px;
}

.keyword-input-container .ant-checkbox-wrapper:nth-child(1) {
    margin-top: 0px;
}

.plus-container {
    display: flex;
    justify-content: space-between;
    margin-top: 3px;
}

.plus-button {
    font-weight: 500;
    font-size: 22px;
    line-height: 18px;
    color: #4c78ee;
    cursor: pointer;
}

.keyword-select-buttons-container {
    display: flex;
    gap: 6px;
    margin-top: 10px;
}

.statistics-table th {
    padding: 0 !important;
    text-align: center !important;
    height: 32px !important;
}

.statistics-table td {
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #4b4b4b;
    height: 32px;
    border-width: 0px 1px 1px 0px;
}

.statistics-table .first-row {
    background: rgba(76, 120, 238, 0.12);
}

.detail-statistics-button {
    font-style: italic;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-align: right;
    color: #82868b;
    cursor: pointer;
}

.total-income-container {
    margin-top: 18px;
    display: flex;
    gap: 20px;
}

.total-income {
    font-size: 16px;
    line-height: 20px;
    color: #4c78ee;
}

.total-income span {
    font-weight: 600;
}

.detailed-statistics-data-container {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    margin-top: 20px;
    padding: 7px 20px;
}

.view-clicks-container {
    display: flex;
    text-align: center;
    gap: 30px;
    font-weight: 600;
    font-size: 12px;
    color: #babfc7;
}

.view-click-value {
    font-size: 14px;
}

.view-click-active {
    color: #4c78ee;
}

.statistics-radio-container {
    border-top: 1px solid #f8f8f8;
    padding-top: 16px;
}

.company-items-header {
    padding-top: 20px;
}

.company-items-table-excel {
    display: flex;
    align-items: center;
    gap: 10px;
}

.download-excel-button {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #4c78ee;
    cursor: pointer;
}

.company-items-table-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #4b4b4b;
}

.statistics-items-table {
    margin-top: 20px;
}

.statistics-items-table th {
    padding: 0 !important;
    text-align: center !important;
}

.statistics-items-table td {
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #4b4b4b;
}

.statistics-items-table .first-row td {
    background: rgb(222, 227, 242);
    border-width: 0px 1px 1px 0px;
    height: 32px !important;
}

.statistics-items-table .first-row td:nth-child(1) {
    border: none !important;
    border-width: 0px !important;
}

.issuing-positions-wrap{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    margin: 20px auto;
}
.issuing-positions-item{
    min-height: 300px;
    border-radius: 20px;
    border: 2px solid #4C78EE;
    background: #FFF;
    padding: 12px 12px 25px 12px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    flex-grow: 1; /* Увеличиваем ширину элементов, если есть свободное место */
    min-width: 350px; /* Минимальная ширина элемента */
    width: 100%;
    max-width: 400px;
}

.issuing-positions-item-info{
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 10px;
}
.issuing-positions-item-info-img{
    max-width: 137px;
    max-height: 180px
}
.issuing-positions-item-info-data{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.issuing-positions-item-info-data-title{
    color: #4B4B4B;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
}
.issuing-positions-item-info-data-elem{
    border-radius: 10px;
    color:  #4C78EE;
    display: inline-flex;
    padding: 2px 9px;
    justify-content: center;
    align-items: center;

    border: 1px solid #4C78EE;
    background: #FFF;

    cursor: pointer;
}
.issuing-positions-item-table {
    height: auto;
    flex: 1
}
.issuing-positions-table{
    min-height: 200px;
    padding: 30px 0;
}

.issuing-positions-item-table-buttons{
    display: flex;
    flex-direction: column;
    gap: 14px;
}
.issuing-positions-item-table-buttons-show-more{
    color: #4C78EE;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 12px;
    font-style: italic;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
    text-decoration-line: underline;
    cursor: pointer;
}

.issuing-positions-table thead th:first-child {
    border-top: none;
}

.issuing-positions-table tbody tr td:first-child {
    border-left: none;
}

.issuing-positions-table tbody tr td:last-child {
    border-right: none;
}

.issuing-positions-table tbody tr:last-child td {
    border-bottom: none;
}

.issuing-positions-table tbody tr td {
    border-top: 1px solid #BABFC7;
    border-bottom: 1px solid #BABFC7;
}


.issuing-positions-table tbody tr td:not(:last-child) {
    border-right: 1px solid #BABFC7;
}

.issuing-positions-table thead th {
    border-right: 1px solid #BABFC7; /* Линии между ячейками */
}

.issuing-positions-table thead th:last-child {
    border-right: none;
}

.issuing-positions-table th{
    color: #4B4B4B;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
}

.issuing-positions-table thead th:nth-child(1) {
    border: none;
}

.issuing-positions-table td:nth-child(0) {
    border-left: none !important;
}
.issuing-positions-table td:nth-child(1) {
    border-right: none !important;
}
.issuing-positions-table tr td {
    border-bottom: none !important;
    color: #4B4B4B;

    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 116.667% */
    padding: 5px
}
.issuing-positions-table tr td:first-child {
    color: #4B4B4B;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
}

.issuing-positions-table th{
    padding: 5px
}

