.table-header-input {
	border-radius: 8px 0 0 8px;
	border: none;
	height: 36px;
	padding-left: 30px;
	/* font-variant-numeric: tabular-nums; */
}
.custom-autocomplete .ant-select-selector {
	width: 100% !important;
	border-radius: 0;
	border: none !important;
	height: 36px !important;
	padding-left: 30px !important;
	padding-top: 5px !important;
}
.ant-select-selector input {
	width: 100% !important;
	border-radius: 0;
	border: none !important;
	height: 36px !important;
	padding-left: 17px !important;
	padding-top: 5px !important;
}

.custom-autocomplete-dropdown .ant-select-dropdown {
	width: 100% !important; /* Задайте фиксированную ширину для контейнера автозаполнения */
}
.custom-autocomplete .ant-select-selection-placeholder {
	font-weight: 600;
	font-size: 12px;
	color: #82868b;
}

.table-header-input-without-border {
	border-radius: 0;
	border: none;
	height: 36px;
	padding-left: 30px;
}

.table-header-input::placeholder {
	font-weight: 600;
	font-size: 12px;
	color: #82868b;
}
.table-header-input-without-border::placeholder {
	font-weight: 600;
	font-size: 12px;
	color: #82868b;
}

.table-header-select {
	width: 100%;
}

.table-header-select-end {
	width: 100%;
	border-radius: 0 8px 8px 0;
}

.table-header-select .ant-select-selector {
	height: 36px !important;
	border-radius: 0;
	border: none !important;
}

.table-header-select .ant-select-selection-item {
	padding-top: 3px !important;
	font-weight: 600;
	font-size: 12px;
	color: #82868b;
}

.table-header-select-no-offset .ant-select-selection-item {
	padding-left: 0 !important;
}

.table-header-select-no-offset .ant-select-selection-item {
	padding-left: 0 !important;
}

.table-header-select-mirror .ant-select-selection-item {
	padding-left: 15% !important;
}
.ant-select-selection-search input {
	padding: 0 !important;
}

.table-header-select .ant-select-arrow {
	font-weight: 600 !important;
}

.table-header-select-mirror .ant-select-arrow {
	padding-right: 10% !important;
}

@media all and (max-width: 1300px) {
	.table-header-select .ant-select-arrow {
		padding-right: 2% !important;
	}
	.table-header-select .ant-select-selection-item {
		padding-left: 10% !important;
	}
}

.table-header-button {
	border-radius: 0 8px 8px 0;
	width: 100%;
	font-weight: 600;
	font-size: 12px;
}

.table-tab-button {
	height: 50px;
	width: 100% !important;
	font-size: 12px;
	line-height: 18px;
	color: #4c78ee;
	border: none;
	font-weight: 600;
}

.table-tab-button-active {
	height: 50px;
	background-color: #4c78ee !important;
	color: #ffffff !important;
	width: 100% !important;
	font-size: 12px;
	line-height: 18px;
	border: none;
	font-weight: 600;
	border-radius: 0 !important;
}

.table-tab-button-without-circle {
	width: 100% !important;
	font-size: 12px;
	line-height: 18px;
	color: #4c78ee;
	border: none;
	font-weight: 600;
	border-radius: 0;
}

.table-tab-button-active-without-circle {
	background-color: #4c78ee !important;
	color: #ffffff !important;
	width: 100% !important;
	font-size: 12px;
	line-height: 18px;
	border: none;
	font-weight: 600;
	border-radius: 0;
}

.table-tab-button-first {
	border-radius: 8px 0 0 0 !important;
}

.table-tab-button-last {
	border-radius: 0 8px 0 0 !important;
}

thead .ant-table-cell {
	padding: 2px 12px !important;
}

thead th {
	text-align: center !important;
	font-size: 12px !important;
}

tbody .ant-table-cell {
	padding: 0 !important;
	height: 48px;
}

.ant-table-column-title {
	text-align: center;
}

.ant-table-wrapper .ant-table-filter-trigger {
	margin-inline: 0 !important;
}

table {
	border-spacing: 1px !important;
}

.render-table-cell {
	height: 100%;
	min-height: 120px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	gap: 3px;
}

.table-mirror-img {
	height: 100%;
	max-height: 100px;
	padding: 1px 0;
}

.ant-pagination .ant-pagination-item-active {
	border-radius: 2px !important;
	border-color: #4c78ee !important;
}

.table-header-add-product {
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: #4c78ee;
	background-color: #ffffff;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 36px;
	cursor: pointer;
}

.custom-table {
	background: #434343;
}

.custom-table thead .ant-table-cell {
	padding: 0px !important;
}

.custom-table .ant-table {
	border: 0px solid transparent;
	padding: 0;
	margin: 0;
	table {
		border: 0px solid transparent;
		border-top: 0px solid transparent !important;
		border-bottom: 0px solid transparent;
		border-right: 0px solid transparent;
		border-left: 0px solid transparent;
	}
}

.custom-table .ant-table-tbody > tr > td,
.custom-table .ant-table-thead > tr > th {
	border-color: transparent;
}

.custom-table .ant-table-cell {
	background-color: rgba(255, 255, 255, 0);
	background: #434343;
	border-inline-end: 0px solid transparent !important;
}
.custom-table .ant-table-thead > tr > th:not(:last-child)::before {
	background-color: transparent !important;
	width: 0 !important;
}

.custom-table .ant-table {
	border: none;
	background-color: transparent;
}

.custom-table .ant-table-tbody > tr > td,
.custom-table .ant-table-thead > tr > th {
	border: none;
	background-color: transparent;
}

.custom-table
	.ant-table-wrapper
	.ant-table.ant-table-bordered
	> .ant-table-container {
	border-inline-start: 0px solid transparent !important;
}

.custom-row {
	height: 25px !important;
	max-height: 25px !important;
}
