.auth-wrap{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 0 20px;
}
.auth-block{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
}
.auth-block-img{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
}

@media (max-width: 1350px) {
    .sidenav-button {
        display: none;
    }
    .auth-block{
        width: 100%;
    }
    .auth-block-img{
        display: none;
    }
}